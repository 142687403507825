/* eslint-disable no-unused-vars */
export interface IResolve<T> {
  Code: number;
  Data: T;
  Message: string;
}
export interface ICoordinates {
  Lat: number;
  Lng: number;
}
export interface IStartEndTimes {
  Start: string;
  End: string;
}
export interface IPaginationParams {
  PageIndex: number;
  PageSize: number;
};
export interface IPaginationData {
  Count: number
  HasNextPage: boolean
  HasPreviousPage: boolean
  PageIndex: number
  TotalPages: number
}
export enum Gender {
  Male=1,
  Female
}
export enum DriverStatus {
  InvitationFailed=1, // 邀请失败
  HasInvited=2, // 已邀请
  HasJoined=3, // 已加入
  ToAudit=4, // 待审核
  IsBinding=5, // 已绑定
  HasBeenDiscontinued=6, // 已停用
}

export type StringifyProperties<T> = {
  [K in keyof T]: string;
};
