import React, { lazy } from 'react';
import {
  Article,
  EditRoad,
  EventNote,
  Gesture,
  GridView,
  Group,
  Groups,
  ManageAccounts,
  MinorCrash,
  MoreTime,
  MyLocation,
  NotificationsActive,
  PendingActions,
  Person,
  Settings,
  SettingsSuggest,
  Watch,
  StackedLineChart,
  Dvr,
  Favorite,
  FactCheck,
  FormatListBulleted,
  Outbox,
  Menu,
  DriveEta,
  Factory,
} from '@mui/icons-material';
import RouterGuard                 from '../components/router-guard';
import MainLayout                  from '../pages/layout';
import { CustomRouteObject }       from './types';

const Login              = lazy(() => import('../pages/login'));
const SpacesManagement   = lazy(() => import('../pages/spaces-management'));
const Notice             = lazy(() => import('../pages/notice'));
const Order              = lazy(() => import('../pages/order'));
const UserManagement     = lazy(() => import('../pages/user-management'));
const Driver             = lazy(() => import('../pages/driver'));
const RegistrationRecord = lazy(() => import('../pages/shift/registrationRecord'));
const ShiftSetting       = lazy(() => import('../pages/shift/setting'));
const ShiftDriver        = lazy(() => import('../pages/shift/driver'));
const Vehicle            = lazy(() => import('../pages/vehicle'));
const VehicleType        = lazy(() => import('../pages/vehicle-type'));
const Address            = lazy(() => import('../pages/address'));
const RouterProjects     = lazy(() => import('../pages/router-project-management/router-project'));
const RouterPlan         = lazy(() => import('../pages/router-project-management/router-plan'));
const UserRole           = lazy(() => import('../pages/user/role'));
const DeliverySearch     = lazy(() => import('../pages/delivery-search'));
const SearchOrder        = lazy(() => import('../pages/delivery-search/searchOrder'));
const UserSetting        = lazy(() => import('../pages/user/setting'));
const WebHooksSettings   = lazy(() => import('../pages/webhooks'));
const RoutingMangement   = lazy(() => import('../pages/router-management'));
const DataCenter         = lazy(() => import('../pages/data-center'));
const ClientManagement   = lazy(() => import('../pages/client-management'));
const Invitation         = lazy(() => import('../pages/h5/invitation'));
const OperationLog       = lazy(() => import('../pages/operation-log'));
const ProjectTemplate    = lazy(() => import('../pages/router-project-management/project-template'));
const DashboardPage      = lazy(() => import('../pages/dashboard/index'));
const H5Evaluation       = lazy(() => import('../pages/h5/evaluation'));
const EvaluationTemplate = lazy(() => import('../pages/evaluation/template'));
const EvaluationAudit    = lazy(() => import('../pages/evaluation/audit'));
const EvaluationSetting  = lazy(() => import('../pages/evaluation/setting'));
const SignatureForm      = lazy(() => import('../pages/signature-form/index'));
const SpaceGroupPage     = lazy(() => import('../pages/space-group/index'));

// const UserSecurity    = lazy(() => import('../pages/user/security'));
const CustomForm         = lazy(() => import('../pages/custom-form'));
const SubmitSetting      = lazy(() => import('../pages/custom-form/submit-setting'));
const FormData           = lazy(() => import('../pages/form-data'));
const OutboxPage         = lazy(() => import('../pages/outbox'));
const MerchantBindingPage = lazy(() => import('../pages/Hos/merchant-binding'));
const VehicleBindingPage = lazy(() => import('../pages/Hos/vehicle-binding'));

const routes: CustomRouteObject[] = [
  {
    path: '/',
    element: <RouterGuard element={<MainLayout />} valid={true}></RouterGuard>,
    children: [
      { title: '儀錶盤', path: '/dashboard', element: <DashboardPage />, meta: { validToken: true, name: 'DASHBOARD' } },
      { title: '區域管理', path: '/spaceGroup', element: <SpaceGroupPage/>, meta: { icon: <GridView />, validToken: true, name: 'SPACESMANAGEMENT' } },
      { title: '區域', path: '/spaces', hidden: true, element: <SpacesManagement/>, meta: { icon: <GridView />, validToken: true, name: 'SPACESMANAGEMENT' } },
      { title: '運單管理', path: '/order', element: <Order />, meta: { icon: <Article />, validToken: true, name: 'ORDER' } },
      { title: '簽收單', path: '/signature-form', element: <SignatureForm />, hidden: true, meta: { validToken: true } },
      { title: '路綫管理', path: '/routing', element: <RoutingMangement/>, meta: { icon: <Gesture />, validToken: true, name: 'ROUTING' } },
      { title: '消息中心', path: '/notice', hidden: true, element: <Notice />, meta: { icon: <NotificationsActive />, validToken: true } },
      {
        title: '路綫項目管理',
        path: '/router',
        meta: { icon: <EventNote /> },
        children: [
          { title: '路綫項目', path: 'project', element: <RouterProjects />, meta: { icon: <Gesture />, validToken: true, name: 'ROUTER.PROJECT' } },
          { title: '路綫規劃', path: 'plan', hidden: true, element: <RouterPlan/>, meta: { icon: <EditRoad />, validToken: true, name: 'ROUTER.PLAN' } },
          { title: '項目模板', path: 'projectTemplate', element: <ProjectTemplate/>, meta: { icon: <Gesture />, validToken: true, name: 'ROUTER.PROJECTTEMPLATE' } },
        ],
      },
      {
        title: '評價管理',
        path: '/evaluation',
        meta: {
          icon: <Favorite />,
        },
        children: [
          { title: '評價审核', path: 'audit', element: <EvaluationAudit />, meta: { icon: <PendingActions />, validToken: true, name: 'EVALUATE.REVIEW' } },
          { title: '評價模板', path: 'template', element: <EvaluationTemplate />, meta: { icon: <FactCheck />, validToken: true, name: 'EVALUATE.TEMPLATE' } },
          { title: '評價設置', path: 'setting', element: <EvaluationSetting/>, meta: { icon: <Settings />, validToken: true, name: 'EVALUATE' } },
        ],
      },
      { title: '司機管理', path: '/driverManagement', element: <Driver />, meta: { icon: <Groups />, validToken: true, name: 'DRIVERMANAGEMENT' } },
      { title: '客戶管理', path: '/clientManagement', element: <ClientManagement/>, meta: { icon: <Groups />, validToken: true, name: 'CUSTOMER' } },
      {
        title: '班次管理',
        path: '/shift',
        meta: {
          icon: <MoreTime />,
        },
        children: [
          { title: '報名記錄', path: 'registrationRecord', element: <RegistrationRecord />, meta: { icon: <PendingActions />, validToken: true, name: 'SHIFT.REGISTRATIONRECORD' } },
          { title: '班次設置', path: 'setting', element: <ShiftSetting />, meta: { icon: <Watch />, validToken: true, name: 'SHIFT.SETTING' } },
          { title: '司機班次設置', path: 'driver', element: <ShiftDriver />, meta: { icon: <ManageAccounts />, validToken: true, name: 'SHIFT.DRIVER' } },
        ],
      },
      { title: '地址管理', path: '/address', element: <Address />, meta: { icon: <MyLocation />, validToken: true, name: 'ADDRESS' } },
      {
        title: '自定義表單',
        path: '/form',
        meta: {
          icon: <FormatListBulleted />,
        },
        children: [
          { title: '表單模板', path: 'template', element: <CustomForm />, meta: { icon: <FormatListBulleted />, validToken: true, name: 'FORM' } },
          { title: '表單提交路徑設置', path: 'setting', element: <SubmitSetting/>, meta: { icon: <Settings />, validToken: true, name: 'FORM' } },
        ],
      },
      { title: '表單數據', path: '/formData', element: <FormData/>, hidden: true, meta: { icon: <FormatListBulleted />, validToken: true } },
      { title: '數據中心', path: '/dataCenter', element: <DataCenter />, meta: { icon: <StackedLineChart />, validToken: true, name: 'DATACENTER' } },
      {
        title: 'HOS',
        path: '/HOS',
        meta: {
          icon: <Menu />,
        },
        children: [
          { title: '商戶綁定', path: 'merchantBinding', element: <MerchantBindingPage />, meta: { icon: <Factory/>, validToken: true, name: 'HOSSUPPLIER' } },
          { title: '車輛綁定', path: 'vehicleBinding', element: <VehicleBindingPage />, meta: { icon: <DriveEta />, validToken: true, name: 'HOSVEHICLE' } },
        ],
      },
      { title: '交通工具管理', path: '/vehicle', element: <Vehicle />, meta: { icon: <MinorCrash />, validToken: true, name: 'VEHICLE' } },
      { title: '車輛類型管理', path: '/vehicleType', element: <VehicleType />, meta: { icon: <MinorCrash />, validToken: true, name: 'VEHICLE.TYPE' } },
      {
        title: '管理/設置',
        path: '/setting',
        meta: {
          icon: <Settings />,
        },
        children: [
          { title: '角色管理', path: 'role', element: <UserRole />, meta: { icon: <Person />, validToken: true, name: 'SETTING.ROLE' } },
          { title: '用戶管理', path: 'manage', element: <UserManagement />, meta: { icon: <Group />, validToken: true, name: 'SETTING.MANAGE' } },
          { title: '業務設置', path: 'notice', element: <UserSetting />, meta: { icon: <SettingsSuggest />, validToken: true, name: 'SETTING.NOTICE' } },
          { title: 'Hooks', path: 'hooks', element: <WebHooksSettings />, meta: { icon: <SettingsSuggest />, validToken: true, name: 'SETTING.HOOKS' } },
          { title: '操作日志', path: 'operationLog', element: <OperationLog />, meta: { icon: <Dvr/>, validToken: true, name: 'SETTING.LOG' } },
          { title: 'Outbox', path: 'outbox', element: <OutboxPage />, meta: { icon: <Outbox/>, validToken: true, name: 'SETTING.LOG' } },
        ],
      },
    ],
  },
  { path: '/login', hidden: true, element: <Login /> },
  { path: '/deliverySearch', hidden: true, element: <DeliverySearch /> },
  { path: '/searchOrder', hidden: true, element: <RouterGuard element={<SearchOrder/>} valid={true}/>  },
  { path: '/h5/invitation', hidden: true, element: <Invitation/> },
  { path: '/h5/evaluation', hidden: true, element: <H5Evaluation/> },
  { path: '*', hidden: true, element: <div>404</div> },
];

export default routes;
